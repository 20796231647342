export default class FormValidation {
  constructor(settings = {
    onAfterInvalid() { },
    onBeforeSubmit() { },
    onAfterSubmit(data, dataSuccess, response, status, xhr, Form) {},
  }) {
    this.settings = settings;
    this.options = {
      activeClass: 'js-validation-init',
    };
    this.$el = $('form.js-validation:not(.' + this.options.activeClass + ')');

    return this;
  }

  init() {
    let _this = this;

    this.$el.each((index, element) => {
      let validationData = { $warnText: null, $response: null, $preloader: null };
      let validationSettings = [];
      const $this = $(element);
      const submitData = $this.data('submit');
      const submitFailData = $this.data('submit-fail');
      const submitSuccessData = $this.data('submit-success');
      let $warnText;
      let $response;
      let $preloader;

      validationData = $this.data('validation') || validationData;

      if (validationData.$warnText !== null) $warnText = $(validationData.$warnText);
      if (validationData.$response !== null) $response = $(validationData.$response);
      if (validationData.$preloader !== null) $preloader = $(validationData.$preloader);

      if ($this.data('validation-settings')) validationSettings = $this.data('validation-settings');
      validationSettings.invalidHandler = () => {
        $this.ajaxSubmit({
          data: { isNaked: 1 },
          success: function (response, status, xhr, Form) {
            const $form = $(Form);
            const $response = $(response);
            const $responseWarnText = $response.filter('.warnText');

            if ($responseWarnText.length && $responseWarnText.length !== false) {
              if ($warnText.length) $warnText.addClass('--active').html($responseWarnText);
              else $form.find('.warnText').html($responseWarnText);
            }else {
              if ($warnText.length) $warnText.addClass('--active').html($response);
              else $form.find('.warnText').html($response);
            }

            _this.settings.onAfterInvalid(submitData, submitFailData, response, status, xhr, Form);
          },
        });
      };

      validationSettings.submitHandler = function (form) {
        const $this_form = $(form);
        const $buttonSubmit = $this_form.find('.js--validation-button-submit');
        let buttonSubmitTextCurrent = '';

        // Заблокировать кнопку
        if ($buttonSubmit.length) {
          const buttonSubmitTextReplace = $buttonSubmit.data('text-submit') || 'Отправка…';
          buttonSubmitTextCurrent = $buttonSubmit.html();

          $buttonSubmit.prop('disabled', true).html(buttonSubmitTextReplace);
        }

        if (validationData.ajax !== 'false') {
          $(form).ajaxSubmit({
            data: { isNaked: 1 },
            beforeSubmit: function (arr, $form, options) {
              if (_this.settings.onBeforeSubmit) _this.settings.onBeforeSubmit(arr, $form, options);
              if (validationData.preloader) {
                $(validationData.preloader).removeClass('-hide');
              }
            },
            success: function (response, status, xhr, Form) {
              if (validationData.no_response !== 'false') {
                var
                  resp = (validationData.response ? $(response).find(validationData.response) : ($(response).filter('.response').length ? $(response).filter('.response') : $(response).find('.response'))),
                  warnText = ($(response).filter('#nc_modal_status').length ? $(response).filter('#nc_modal_status') : $(response).find('#nc_modal_status')),
                  all = $(response);

                if (warnText.length) {
                  const $error = $('.js--art-market-notice-error');
                  if ($error.length) {
                    $error.addClass('--active').removeClass('-hide');
                    $error.find('.js--art-market-notice-error-message').html(warnText.length && warnText.length !== 'false' ? warnText : all);
                  }else {
                    if (warnText.length && warnText.length !== 'false') {
                      if (validationData.warnText != null) $(validationData.warnText).addClass('--active').html(warnText);
                      else $this_form.find('.warnText').html(warnText);
                    }else {
                      if (validationData.warnText != null) $(validationData.warnText).addClass('--active').html(all);
                      else $this_form.find('.warnText').html(all);
                    }
                  }
                }else {
                  if (validationData.selector !== false) {
                    if (resp.length && resp.length !== 'false') {
                      if (validationData.selector) $(validationData.selector).addClass('--active').html(resp);
                      else $this_form.closest('div').html(resp);
                    }else {
                      if (validationData.selector) $(validationData.selector).addClass('--active').html(all);
                      else $this_form.closest('div').html(all);
                    }
                  }
                }
              }

              // Разблокировать кнопку
              if ($buttonSubmit.length) {
                $buttonSubmit.prop('disabled', false).html(buttonSubmitTextCurrent);
              }

              if (validationData.history) history.pushState(null, null, $(form).attr('action') + '?' + $(form).formSerialize());

              if (validationData.preloader) {
                $(validationData.preloader).addClass('-hide');
              }

              _this.settings.onAfterSubmit(submitData, submitSuccessData, response, status, xhr, Form);

              new FormValidation().init();
            },
          });
        }
      };

      $this.addClass(_this.options.activeClass).validate(validationSettings);
    });
  }

  render() {
    if (this.$el.length) {
      this.init();
    }
    return this;
  }
}
